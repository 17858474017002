<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Contact Area  -->
        <div class="rn-contact-area rn-section-gap bg_color--5">
            <div class="contact-form--1">
                <v-container>

                    <div class="container">
                        <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
                            <div style="display: flex; flex-direction: row;">
                                <h2 class="heading-title">Events</h2>
                                <v-spacer></v-spacer>
                                <v-btn-toggle v-model="toggleSort">
                                    <v-btn>
                                        <v-icon>
                                            {{ 'mdi-sort-ascending' }}
                                        </v-icon>
                                    </v-btn>
                                    <v-btn>
                                        <v-icon>
                                            {{ 'mdi-sort-descending' }}
                                        </v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                            <p class="description">
                                Unveilling the journey of Our Company through spectacular events
                            </p>
                            <div v-bind:key="index" v-for="(item, index) in sortedEventsItem">
                                <v-list-item v-on:click="openDialog = true; contentDialog = item">
                                    <v-row no-gutters style="margin-top:20px; margin-bottom: 20px;">
                                        <v-col md="3" style="padding: 0px !important; margin: 0px !important;">
                                            <v-img style="box-shadow: 0px 0px 10px 1px orange; max-width: 600px;"
                                                :src="item.img" aspect-ratio="4"></v-img>
                                        </v-col>
                                        <v-col md="9">
                                            <div class="pl-7">
                                                <v-row>
                                                    <v-col>
                                                        <span> {{ item.date }} </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <span class="headline"
                                                            style="display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: left;">
                                                            {{ item.title }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Contact Area  -->
        <v-dialog v-model="openDialog" width="70vw">
            <v-card style="width: 100%;">
                <v-img :src="contentDialog.img" aspect-ratio="4"></v-img>
                <v-card-title style="position: relative;" class="d-flex align-center justify-center">
                    <div style="text-align: center;">
                        <h3>
                            {{ contentDialog.title }}
                        </h3>
                        <span>{{ contentDialog.date }}</span>
                    </div>
                </v-card-title>
                <v-card-text style="padding: 20px;">
                    <span>{{ contentDialog.description }}</span>
                    <div class="d-flex align-center justify-center" style="margin: 20px;">
                        <iframe ref="iframe" width="560" height="315" :src="openDialog != false ? contentDialog.link : ''"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../src/components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../src/components/header/HeaderOnePageTwoSiberMobile.vue";
import Footer from "../components/footer/Footer.vue";
export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        Footer,
    },
    computed: {
        sortedEventsItem() {
            if (this.toggleSort == 0) {

                return this.eventsItem.sort((a, b) => {
                    const date1 = new Date(a.date.split('/').reverse().join('/'))
                    const date2 = new Date(b.date.split('/').reverse().join('/'))
                    return date2 - date1
                })
            } else if (this.toggleSort == 1) {

                return this.eventsItem.sort((a, b) => {
                    const date1 = new Date(a.date.split('/').reverse().join('/'))
                    const date2 = new Date(b.date.split('/').reverse().join('/'))
                    return date1 - date2
                })
            }
        }
    },
    data() {
        return {
            openDialog: false,
            contentDialog: {},
            toggleSort: 0,
            eventsItem: [
                {
                    title: 'Winning in Digital Disruption Era Day 1',
                    date: '28/09/2022',
                    description: 'Sibernetik x Katadata telah mengadakan acara dengan mengangkat tema Winning in Digital Disruption Era. Untuk pembicara pada hari pertama sesi pertama ada Ibu Ari Pratiwi (Head of IT Architecture & Strategy Division BNI) dan Bapak Maulana Yusuf (Head Enterprise Data Management BRI) dengan sub-tema Winning in Digital Disruption Era. Pada sesi kedua dilanjutkan dengan sub-tema Ready for Digital Disruption dengan pembicara Bapak Victor Erico Korompis (SVP Digital Banking Delivery Bank Mandiri), Mr. Kenny Lee (Sales Director Imply ASEAN & India), Bapak Arief Hasani (CTO Sibernetik Integra Data) dan Bapak Rully Moulany (Sales Director Confluent ASEAN).',
                    img: require('../assets/images/service/events/Preroll Sibernatik-01.jpg'),
                    link: 'https://www.youtube.com/embed/CgzcWfq7No0',
                },
                {
                    title: 'Winning in Digital Disruption Era Day 2',
                    date: '29/09/2022',
                    description: 'Sibernetik x Katadata telah mengadakan acara dengan mengangkat tema Winning in Digital Disruption Era. Untuk pembicara pada hari pertama sesi pertama ada Tommy Wattimena(CEO Great Giant Food) dan Bapak Yoseph Budi Wicaksono(GM Supply Chain Development & Performance PT Semen Indonesia) dengan sub- tema Active intelligence in Supply Chain.Pada sesi kedua dilanjutkan dengan sub - tema Delivering Faster, Delivering Cheaper dengan pembicara Bapak Reska Donaga(VP IT Consumer Product & Special Project SiCepat), Bapak Andreas Nataniel(Country Director Qlik Indonesia), Mr.Sunil Kumar(Country Manager Aerospike ASEAN), dan Bapak Arief Hasani(CTO Sibernetik Integra Data).',
                    img: require('../assets/images/service/events/Preroll Sibernatik-01.jpg'),
                    link: 'https://www.youtube.com/embed/2gzu0--vB0U',
                },
            ]
        };
    },
};
</script>
<style scoped>
.container {
    display: block;
    margin: auto;
    text-align: left;
    border-radius: 10px;
    background-color: #ffff;
    padding: 50px;
}
</style>
  